import React, { useState, useEffect } from 'react';
import { getEmailMonitoring, deleteUserPreference } from './api'; // Import the delete function

function MonitoringPage() {
    const [monitorData, setMonitorData] = useState([]);

    useEffect(() => {
        const fetchMonitoringData = async () => {
            try {
                const data = await getEmailMonitoring();
                console.log("Fetched Monitoring Data:", data); // Log data to ensure 'id' is present
                setMonitorData(data);
            } catch (error) {
                console.error('Error fetching monitoring data:', error);
            }
        };

        fetchMonitoringData();
    }, []);

    const handleDelete = async (id) => {
        console.log("Deleting notification with id:", id); // Log id before making API call
        try {
            await deleteUserPreference(id);
            // Remove the deleted notification from the state
            setMonitorData(monitorData.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const adjustedHours = hours % 12 || 12;
        return `${adjustedHours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${ampm}`;
    };

    return (
        <div className="monitoring-page bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-3xl mx-auto flex flex-col gap-6 md:max-w-2xl lg:max-w-xl">
            <h2 className="text-2xl font-bold text-green-800 text-center">Email Monitoring</h2>
            <ul className="monitoring-list">
                {monitorData.map((item, index) => (
                    <li key={index} className="monitoring-item bg-green-50 p-4 mb-4 rounded-md shadow-md">
                        <p><strong>Email:</strong> {item.email || 'N/A'}</p>
                        <p><strong>Phone:</strong> {item.phone || 'N/A'}</p>
                        <p><strong>Course:</strong> {item.course}</p>
                        <p><strong>Date:</strong> {item.date}</p>
                        <p><strong>Start Time:</strong> {formatTime(item.start_time)}</p>
                        <p><strong>End Time:</strong> {formatTime(item.end_time)}</p>
                        <p><strong>Email Sent:</strong> {item.email_sent ? new Date(item.email_sent).toLocaleString() : 'Not Sent'}</p>
                        <button
                            onClick={() => handleDelete(item.id)}
                            className="delete-button bg-red-600 text-white py-1 px-3 rounded-md mt-4"
                        >
                            Delete
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default MonitoringPage;
