// frontend/src/api.js
const API_URL = 'https://api.denvertts303.com';
// const API_URL = 'http://localhost:8000';


export const getTeeTimes = async (date) => {
  console.log("Fetching tee times for date:", date);
  const response = await fetch(`${API_URL}/tee-times`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ date }),
  });

  if (response.ok) {
    console.log("Successfully fetched tee times");
    return response.json();
  } else {
    console.error("Error fetching tee times:", response.statusText);
    throw new Error('Error fetching tee times');
  }
};

export const saveUserPreferences = async (preferences) => {
  console.log("Saving user preferences:", preferences);
  const response = await fetch(`${API_URL}/save-preferences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(preferences),
  });

  if (response.ok) {
    console.log("Successfully saved user preferences");
    return response.json();
  } else {
    console.error("Error saving preferences:", response.statusText);
    throw new Error('Error saving preferences');
  }
};

// New API call to get the monitoring data
export const getEmailMonitoring = async () => {
  console.log("Fetching email monitoring data");
  const response = await fetch(`${API_URL}/monitor-emails`);

  if (response.ok) {
    console.log("Successfully fetched email monitoring data");
    return response.json();
  } else {
    console.error("Error fetching email monitoring data:", response.statusText);
    throw new Error('Error fetching email monitoring data');
  }
};

export const deleteUserPreference = async (id) => {
  const response = await fetch(`${API_URL}/delete-preferences/${id}`, {
    method: 'DELETE',
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Error deleting preference');
  }
};
