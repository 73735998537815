import React, { useState, useEffect } from 'react';
import { getTeeTimes, saveUserPreferences } from './api';
import { Link } from 'react-router-dom';
import './index.css';

function TeeTimesPage() {
    const [date, setDate] = useState('');
    const [teeTimes, setTeeTimes] = useState([]);
    const [alertsCourseFilter, setAlertsCourseFilter] = useState('');
    const [alertsStartTimeFilter, setAlertsStartTimeFilter] = useState(0);
    const [alertsEndTimeFilter, setAlertsEndTimeFilter] = useState(1440);
    const [email, setEmail] = useState('');
    const [fetchCourseFilter, setFetchCourseFilter] = useState('');
    const [fetchStartTimeFilter, setFetchStartTimeFilter] = useState(0);
    const [fetchEndTimeFilter, setFetchEndTimeFilter] = useState(1440);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setDate(today);
    }, []);

    const fetchTeeTimes = async () => {
        try {
            const data = await getTeeTimes(date);
            setTeeTimes(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSavePreferences = async () => {
        try {
            await saveUserPreferences({
                email,
                date,
                startTime: alertsStartTimeFilter,
                endTime: alertsEndTimeFilter,
                course: alertsCourseFilter
            });
            alert('Preferences saved! You will receive alerts when matching tee times become available.');
        } catch (error) {
            console.error(error);
        }
    };

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const adjustedHours = hours % 12 || 12;
        return `${adjustedHours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${ampm}`;
    };

    const predefinedCourses = [
        "Kennedy Par 3",
        "Kennedy (Creek 9 only)",
        "Kennedy (West 9 only)",
        "Kennedy (Babe Lind / Creek)",
        "Evergreen",
        "Willis Case Back Nine",
        "Willis Case",
        "Harvard Gulch Par 3",
        "Overland Park",
        "Wellshire",
        "Evergreen Back Nine",
        "City Park"
    ];

    return (
        <div className="App bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-3xl mx-auto flex flex-col gap-6 md:max-w-2xl lg:max-w-xl">
            <header className="App-header">
                <h1 className="text-2xl font-bold text-green-800 text-center">Tee Time Alerts</h1>

                {/* Invisible Link to Monitoring Page */}
                <Link
                    to="/monitoring"
                    style={{
                        color: 'white', // Matches the background color to make it invisible
                        textDecoration: 'none',
                    }}
                >
                    &nbsp;
                </Link>

                <div className="subscribe-alerts-form bg-green-50 p-6 rounded-lg shadow-md mt-6">
                    <h2 className="text-xl font-semibold text-green-600 mb-4 text-center">Add an Alert</h2>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="contact-input p-2 mb-2 border border-gray-300 rounded-md w-full"
                    />
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="date-picker p-2 mb-2 border border-gray-300 rounded-md w-full"
                    />
                    <div className="filter-group mb-2">
                        <label htmlFor="alertsCourseFilter" className="font-semibold text-green-800">Course:</label>
                        <select
                            id="alertsCourseFilter"
                            value={alertsCourseFilter}
                            onChange={(e) => setAlertsCourseFilter(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        >
                            <option value="">All Courses</option>
                            {predefinedCourses.map(course => (
                                <option key={course} value={course}>
                                    {course}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-group mb-2">
                        <label className="font-semibold text-green-800">Time Range:</label>
                        <div className="flex items-center gap-2">
                            <span>Start Time: {formatTime(alertsStartTimeFilter)}</span>
                            <input
                                type="range"
                                min="0"
                                max="1440"
                                value={alertsStartTimeFilter}
                                onChange={(e) => setAlertsStartTimeFilter(parseInt(e.target.value, 10))}
                                className="flex-1"
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <span>End Time: {formatTime(alertsEndTimeFilter)}</span>
                            <input
                                type="range"
                                min="0"
                                max="1440"
                                value={alertsEndTimeFilter}
                                onChange={(e) => setAlertsEndTimeFilter(parseInt(e.target.value, 10))}
                                className="flex-1"
                            />
                        </div>
                    </div>
                    <button onClick={handleSavePreferences} className="save-button bg-green-700 text-white py-2 px-4 rounded-md w-full mt-4">Add</button>
                </div>

                <div className="fetch-tee-times-form bg-green-50 p-6 rounded-lg shadow-md mt-6">
                    <h2 className="text-xl font-semibold text-green-600 mb-4 text-center">Search Tee Times</h2>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="date-picker p-2 mb-2 border border-gray-300 rounded-md w-full"
                    />
                    <button onClick={fetchTeeTimes} className="fetch-button bg-green-700 text-white py-2 px-4 rounded-md w-full mb-4">Search</button>
                    <div className="filter-group mb-2">
                        <label htmlFor="fetchCourseFilter" className="font-semibold text-green-800">Filter by Course:</label>
                        <select
                            id="fetchCourseFilter"
                            value={fetchCourseFilter}
                            onChange={(e) => setFetchCourseFilter(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        >
                            <option value="">All Courses</option>
                            {predefinedCourses.map(course => (
                                <option key={course} value={course}>
                                    {course}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-group mb-2">
                        <label className="font-semibold text-green-800">Filter by Time Range:</label>
                        <div className="flex items-center gap-2">
                            <span>Start Time: {formatTime(fetchStartTimeFilter)}</span>
                            <input
                                type="range"
                                min="0"
                                max="1440"
                                value={fetchStartTimeFilter}
                                onChange={(e) => setFetchStartTimeFilter(parseInt(e.target.value, 10))}
                                className="flex-1"
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <span>End Time: {formatTime(fetchEndTimeFilter)}</span>
                            <input
                                type="range"
                                min="0"
                                max="1440"
                                value={fetchEndTimeFilter}
                                onChange={(e) => setFetchEndTimeFilter(parseInt(e.target.value, 10))}
                                className="flex-1"
                            />
                        </div>
                    </div>
                    <ul className="tee-time-list mt-4">
                        {teeTimes.flatMap((teeTimeGroup, index) =>
                            teeTimeGroup.items
                                .filter(teeTimeItem =>
                                    (fetchCourseFilter === '' || teeTimeItem.name === fetchCourseFilter) &&
                                    (fetchStartTimeFilter <= teeTimeGroup.teeTime && teeTimeGroup.teeTime <= fetchEndTimeFilter)
                                )
                                .map((teeTimeItem, subIndex) => (
                                    <li key={`${index}-${subIndex}`} className="tee-time-item bg-green-700 text-white rounded-md p-2 my-2">
                                        {`${teeTimeItem.name} at ${formatTime(teeTimeGroup.teeTime)}`}
                                    </li>
                                ))
                        )}
                    </ul>
                </div>
            </header>
        </div>
    );
}

export default TeeTimesPage;
